.card-body-toolbar {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.error-message {
  color: red;
  font-size: 13px;
}

// Card
.card {
  border: none;
  .card-header {
    background: white;
    padding: 3px 10px;

    .title {
      padding: 3px 0;
    }

    .sub-title {
      font-size: 12px;
    }
  }
}

// Primeng
.p-dropdown,
.p-calendar,
.p-inputtext {
  width: 100%;
}
textarea.p-inputtext {
  resize: none;
}
.p-table-caption {
  background: none !important;
}

body .p-paginator {
  background: none !important;
  border: none !important;
  padding: 1em !important;
}

.p-table p-sorticon {
  float: right;
}
:root body .p-table .p-table-thead > tr > th {
  border: none;
}

body .p-table .p-table-caption,
body .p-table .p-table-summary {
  border: none !important;
  border-bottom: 1px solid $mainBackgroundColor !important;
}

.p-table-caption-container {
  text-align: left;
  max-width: 100%;

  > div {
    max-width: 100%;
  }

  .p-table-name {
    font-weight: normal;
    margin-right: 100px;
  }

  input.search {
    border: none;
    border-bottom: 1px solid $mainBackgroundColor;
    max-width: 100%;
  }
}

.p-table {
  background: white;
}

.p-card-header {
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
  border-bottom: 1px solid $mainBackgroundColor;
}

.p-card-content {
  padding: 0px 20px;
}

.p-fluid .p-inputgroup .p-inputtext {
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.p-inputgroup.card-input .p-inputtext {
  border: none;
  border-bottom: 1px solid $mainBackgroundColor;
}

.p-inputgroup.card-input .p-inputgroup-addon {
  background: none !important;
  border: none !important;
}

.p-outlined-button {
  background: none !important;
  color: black !important;
}

.p-dialog {
  // .p-dialog-titlebar {
  //     background: $headerFontColor !important;
  //     color: white !important;
  // }

  // .p-dialog-titlebar {
  //     border: none !important;
  //     .p-dialog-titlebar-icon {
  //         color: white !important;
  //     }
  // }

  h3 {
    margin-top: 30px;
    font-size: 16px;
    font-weight: bold;
  }

  p {
    padding: 10px 0;
  }

  .buttons {
    text-align: center;
    margin-top: 30px;
  }
}

:root ::ng-deep input.ng-touched.ng-pristine.ng-invalid {
  border-bottom: 2px solid var(--danger) !important;
}

:root ::ng-deep .p-dialog-content {
  // border-radius: 10px !important;
}

:root ::ng-deep .p-datepicker.p-widget.p-widget-content {
  border-color: #eeeeee !important;
}

:root ::ng-deep .p-dialog-titlebar {
  // display: none;
}

:root ::ng-deep .p-confirmdialog {
  .p-dialog-content {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .p-dialog-footer {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
  }
}

:root {
  ::ng-deep .table-rounded-button {
    border-radius: 50% !important;
    background: none !important;
    color: #007ad9 !important;
  }

  ::ng-deep .table-rounded-button.p-button-danger {
    color: #e91224 !important;
  }

  ::ng-deep {
    perfect-scrollbar {
      padding-right: 5px;
    }
  }

  ::ng-deep .custom-table-top-button {
    span.p-button-text {
      margin-left: 5px !important;
    }
  }

  ::ng-deep .prime-text-with-icon-right {
    position: relative;
    span {
      position: absolute;
      top: 1px;
      right: 1px;
      background: #f1f8fc;
      display: inline-block;
      padding: 5px 8px 4px;
    }
  }
}
label {
  font-size: 14px;
}
.btn-white-font {
  color: white !important;
}
.app-breadcrumb {
  padding: 15px;
  font-size: 13px;
  a {
    color: black;
    display: inline-block;
    padding: 3px;
    font-weight: 500;
  }
}

::placeholder {
  color: #ccc;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: #ccc;
}
