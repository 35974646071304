.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.capitalize {
  text-transform: capitalize;
}

.shadow-center-25 {
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
}

.clear::after {
  position: relative;
  clear: both;
  content: "";
  display: block;
}

.pointer {
  cursor: pointer !important;
}

.relative {
  position: relative;
}

.font-black {
  color: black;
}

.font-red {
  color: red !important;
}

.font-green {
  color: green !important;
}

.font-yellow {
  color: rgb(255, 153, 0) !important;
}

.font-blue-green {
  color: #00a89f !important;
}

.font-color-primary {
  color: var(--primary-color);
}

.font-color-primary-dark {
  color: var(--primary-dark-color);
}

.font-color-gray-light {
  color: var(--gray-light-color);
}

.font-color-gray-medium {
  color: var(--gray-medium-color);
}

.font-weight-500 {
  font-weight: 500;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-13 {
  font-size: 13px;
}

.font-size-16 {
  font-size: 16px;
}
.font-size-18 {
  font-size: 18px;
}

.bold {
  font-weight: bold;
}
.italic {
  font-style: italic;
}

.bolded-labels {
  label {
    font-weight: bold;
  }
}

.no-margins {
  margin: 0;
}

.margin-left-50 {
  margin-left: 50px;
}

.margin-left-30 {
  margin-left: 30px;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.align-sub {
  vertical-align: sub !important;
}

.border-bottom {
  border-bottom: 1px solid #eee;
}

.underlined-text {
  text-decoration: underline !important;
}

.separator {
  border-bottom: 1px solid #eee;
}

.uppercase {
  text-transform: uppercase;
}

.green {
  color: yellowgreen;
}

.red {
  color: orangered;
}

.ui-button-outlined {
  background-color: transparent !important;
  color: black !important;
}

.ui-button-outlined.ui-button-danger {
  color: var(--danger) !important;
}

.ui-button-outlined.ui-button-success {
  color: var(--success) !important;
}

.ui-button-outlined:hover {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

::ng-deep .ui-button-empty {
  background-color: transparent !important;
  border: none !important;
  color: #456ab2 !important;
  .icon-new-patient {
    font-size: 26px !important;
    left: 1% !important;
    top: 45% !important;
    // margin-right: 25px !important;
    // color: red !important;
  }
  .icon-new-request {
    font-size: 26px !important;
    left: 1% !important;
    top: 45% !important;
    // margin-right: 25px !important;
    // color: red !important;
  }
}

::ng-deep .table-button-empty {
  background-color: transparent !important;
  border: none !important;
  font-size: 24px !important;
  padding: 0 !important;
}

.custom-tabs-container {
  border-bottom: 1px solid #d1d1d1;
  display: flex;
  .custom-tab {
    font-weight: bold;
    border: 1px solid #d1d1d1;
    border-bottom: 3px solid transparent;
    padding: 5px 25px;
    cursor: pointer;
    &.active {
      background: white;
      border-bottom: 3px solid #00a89f;
      color: #00a89f;
    }
  }
}
