// Sidebar
$sidebarFontFirstColor: #000;
$sidebarFontSecondColor: #000;
$sidebarBackgroundFirstColor: white;
$sidebarBackgroundSecondColor: #b1dcaf;
$sidebarActiveLinkBackground: #b1dcaf;
$sidebarRightBorderColor: #00263b;

// Header
$headerBackgroundColor: #bbe7b9;
$headerFontColor: #000000;

// Main Colors
$blueLight: #9bd3e4;
$grayLight: #f5f5ff;
$mainBackgroundColor: #f5f5ff;
$mainFontColor: #93db8f;
$secondaryFontColor: #ff5455;
$thirdFontColor: #ef9c40;

// Popups
$popupTitleColor: #1e1e2d;
$popupTitleFontColor: #a2a3b7;

:root {
  --success-color: #b1dcaf;
  --success-color-dark: #8cb48a;
  --primary-color: #b1dcaf;
  --primary-dark-color: #569d51;
  --danger-color: #ff5455;
  --gray-light-color: #d8d8d8;
  --gray-medium-color: #9b9b9b;
  --header-font-color: #000000;
  --header-button-background: #93db8f;
  --sidebar-font-color: #000000;
  --badge-background-color: #ef5a22;
}
