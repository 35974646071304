// @import "~@swimlane/ngx-datatable/index.css";
// @import "~@swimlane/ngx-datatable/themes/bootstrap.scss";
// @import "~@swimlane/ngx-datatable/assets/icons.css";
@import "./app/theme/scss/variables.scss";
@import "./app/theme/scss/classes.scss";
@import "./app/theme/scss/template.scss";
@import "./app/theme/scss/connect-icons.scss";
// @import "./app/theme/scss/gatekeeper-icons.scss";
@import "./app/theme/scss/primeng.scss";
@import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";

body {
  background-color: #f2f3f8 !important;
}

// ------------------------------------------------------------------------------------------------//

// ###########################  NGX-Datatable   ###################################//
.bootstrap.ngx-datatable {
  background: white;
  @extend .shadow-center-25;
}

datatable-body-row:hover {
  background: #a9a9a9 !important;
}

.ngx-datatable.bootstrap .datatable-footer {
  background: #ffffff;
  border-top: 1px solid #cccc;
  color: black;
}

.ngx-datatable.bootstrap .datatable-header {
  background: $blueLight;
}
// ###########################  NGX-Datatable   ###################################//

// ------------------------------------------------------------------------------------------------//

// ###########################  POPUPS   ###################################//
:root {
  ::ng-deep .iti-popup {
    border-radius: 5px;

    .popup-header {
      color: #000000;
      padding: 10px 0;
    }
  }
  .popup-close-button {
    float: right;
    padding-right: 10px;
    cursor: pointer;
  }

  ::ng-deep input.ng-touched.ng-pristine.ng-invalid {
    // border-bottom: 1px solid red;
    border: 1px solid var(--danger);
  }

  ::ng-deep .dx-overlay-shader {
    background: rgba(0, 0, 0, 0.3);
  }

  body .ui-table .ui-table-thead > tr > th {
    background: white !important;
  }

  body .ui-table .ui-sortable-column.ui-state-highlight,
  body .ui-table .ui-sortable-column.ui-state-highlight .ui-sortable-column-icon {
    color: black !important;
  }
}

// ###########################  POPUPS   ###################################//

// ###########################  PRIMENG  ###################################//

.ui-card-content {
  padding: 0;
}

.ui-table-thead {
  .ui-sortable-column {
    p-sorticon {
      visibility: hidden;
    }
  }
}

.ui-table-tbody {
  td {
    > div {
      overflow: hidden;
    }
  }
}

body .ui-table .ui-table-tbody > tr.ui-state-highlight {
  background-color: #afafaf !important;
}

body .ui-table .ui-table-tbody > tr > td {
  border-color: #f1f1f1 !important;
  border-right: none !important;
  border-left: none !important;
}

:root body .ui-table .ui-table-thead > tr > th {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  min-height: 40px;
}

body .ui-table .ui-table-thead > tr > th {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  min-height: 40px;
}

.p-table-name {
  font-weight: bold !important;
}
// ########################### END PRIMENG  ###################################//
